export default [
    {
        path: '/sector/list',
        name: 'definition-sector-list',
        component: () => import('@/views/definition/sector/List.vue'),
        meta: {
            pageTitle: 'Sektör Tanımları'
        },
    },
    {
        path: '/sector/add/',
        name: 'definition-sector-add',
        component: () => import('@/views/definition/sector/Detail.vue'),
        meta: {
            pageTitle: 'Sektör Ekle',
            breadcrumb: [
                {
                    text: 'Sektör Tanımları',
                    to: {name: 'definition-sector-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sector/edit/:id',
        name: 'definition-sector-edit',
        component: () => import('@/views/definition/sector/Detail.vue'),
        meta: {
            pageTitle: 'Sektör Değiştir',
            breadcrumb: [
                {
                    text: 'Sektör Tanımları',
                    to: {name: 'definition-sector-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/region/list',
        name: 'definition-region-list',
        component: () => import('@/views/definition/region/List.vue'),
        meta: {
            pageTitle: 'Bölge Tanımları'
        },
    },
    {
        path: '/region/add/',
        name: 'definition-region-add',
        component: () => import('@/views/definition/region/Detail.vue'),
        meta: {
            pageTitle: 'Bölge Ekle',
            breadcrumb: [
                {
                    text: 'Bölge Tanımları',
                    to: {name: 'definition-region-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/region/edit/:id',
        name: 'definition-region-edit',
        component: () => import('@/views/definition/region/Detail.vue'),
        meta: {
            pageTitle: 'Bölge Değiştir',
            breadcrumb: [
                {
                    text: 'Bölge Tanımları',
                    to: {name: 'definition-region-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/salesman/list',
        name: 'definition-salesman-list',
        component: () => import('@/views/definition/salesman/List.vue'),
        meta: {
            pageTitle: 'Plasiyer Tanımları'
        },
    },
    {
        path: '/salesman/add/',
        name: 'definition-salesman-add',
        component: () => import('@/views/definition/salesman/Detail.vue'),
        meta: {
            pageTitle: 'Plasiyer Ekle',
            breadcrumb: [
                {
                    text: 'Plasiyer Tanımları',
                    to: {name: 'definition-salesman-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/salesman/edit/:id',
        name: 'definition-salesman-edit',
        component: () => import('@/views/definition/salesman/Detail.vue'),
        meta: {
            pageTitle: 'Plasiyer Değiştir',
            breadcrumb: [
                {
                    text: 'Plasiyer Tanımları',
                    to: {name: 'definition-salesman-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/customer/list',
        name: 'definition-customer-list',
        component: () => import('@/views/definition/customer/List.vue'),
        meta: {
            pageTitle: 'Müşteri Tanımları'
        },
    },
    {
        path: '/customer/add/',
        name: 'definition-customer-add',
        component: () => import('@/views/definition/customer/Detail.vue'),
        meta: {
            pageTitle: 'Müşteri Ekle',
            breadcrumb: [
                {
                    text: 'Müşteri Tanımları',
                    to: {name: 'definition-customer-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/customer/edit/:id',
        name: 'definition-customer-edit',
        component: () => import('@/views/definition/customer/Detail.vue'),
        meta: {
            pageTitle: 'Müşteri Değiştir',
            breadcrumb: [
                {
                    text: 'Müşteri Tanımları',
                    to: {name: 'definition-customer-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/complaint-subject/list',
        name: 'definition-complaint-subject-list',
        component: () => import('@/views/definition/complaint_subject/List.vue'),
        meta: {
            pageTitle: 'Şikayet Konu Tanımları'
        },
    },
    {
        path: '/complaint-subject/add/',
        name: 'definition-complaint-subject-add',
        component: () => import('@/views/definition/complaint_subject/Detail.vue'),
        meta: {
            pageTitle: 'Şikayet Konu Ekle',
            breadcrumb: [
                {
                    text: 'Şikayet Konu Tanımları',
                    to: {name: 'definition-complaint-subject-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/complaint-subject/edit/:id',
        name: 'definition-complaint-subject-edit',
        component: () => import('@/views/definition/complaint_subject/Detail.vue'),
        meta: {
            pageTitle: 'Şikayet Konu Değiştir',
            breadcrumb: [
                {
                    text: 'Şikayet Konu Tanımları',
                    to: {name: 'definition-complaint-subject-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/project/list',
        name: 'definition-project-list',
        component: () => import('@/views/definition/project/List.vue'),
        meta: {
            pageTitle: 'Proje Tanımları'
        },
    },
    {
        path: '/project/add/',
        name: 'definition-project-add',
        component: () => import('@/views/definition/project/Detail.vue'),
        meta: {
            pageTitle: 'Proje Ekle',
            breadcrumb: [
                {
                    text: 'Proje Tanımları',
                    to: {name: 'definition-project-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/project/edit/:id',
        name: 'definition-project-edit',
        component: () => import('@/views/definition/project/Detail.vue'),
        meta: {
            pageTitle: 'Proje Değiştir',
            breadcrumb: [
                {
                    text: 'Proje Tanımları',
                    to: {name: 'definition-project-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form/list',
        name: 'definition-form-list',
        component: () => import('@/views/definition/form/List.vue'),
        meta: {
            pageTitle: 'Form Tanımları'
        },
    },
    {
        path: '/form/add/',
        name: 'definition-form-add',
        component: () => import('@/views/definition/form/Detail.vue'),
        meta: {
            pageTitle: 'Form Ekle',
            breadcrumb: [
                {
                    text: 'Form Tanımları',
                    to: {name: 'definition-form-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form/edit/:id',
        name: 'definition-form-edit',
        component: () => import('@/views/definition/form/Detail.vue'),
        meta: {
            pageTitle: 'Form Değiştir',
            breadcrumb: [
                {
                    text: 'Form Tanımları',
                    to: {name: 'definition-form-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/access/list',
        name: 'definition-access-list',
        component: () => import('@/views/definition/access/List.vue'),
        meta: {
            pageTitle: 'Erişim Tanımları'
        },
    },
    {
        path: '/access/add/',
        name: 'definition-access-add',
        component: () => import('@/views/definition/access/Detail.vue'),
        meta: {
            pageTitle: 'Erişim Ekle',
            breadcrumb: [
                {
                    text: 'Erişim Tanımları',
                    to: {name: 'definition-access-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/access/edit/:id',
        name: 'definition-access-edit',
        component: () => import('@/views/definition/access/Detail.vue'),
        meta: {
            pageTitle: 'Erişim Değiştir',
            breadcrumb: [
                {
                    text: 'Erişim Tanımları',
                    to: {name: 'definition-access-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
]
