export default [
    {
        path: '/settings',
        name: 'system-settings',
        component: () => import('@/views/system/settings/Index.vue'),
        meta: {
            pageTitle: 'Ayarlar'
        },
    },
    {
        path: '/role/list',
        name: 'system-role-list',
        component: () => import('@/views/system/role/List.vue'),
        meta: {
            pageTitle: 'Rol Yönetimi'
        },
    },
    {
        path: '/role/add/',
        name: 'system-role-add',
        component: () => import('@/views/system/role/Detail.vue'),
        meta: {
            pageTitle: 'Rol Ekle',
            breadcrumb: [
                {
                    text: 'Rol Yönetimi',
                    to: {name: 'system-role-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/role/edit/:id',
        name: 'system-role-edit',
        component: () => import('@/views/system/role/Detail.vue'),
        meta: {
            pageTitle: 'Rol Değiştir',
            breadcrumb: [
                {
                    text: 'Rol Yönetimi',
                    to: {name: 'system-role-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/list',
        name: 'system-user-list',
        component: () => import('@/views/system/user/List.vue'),
        meta: {
            pageTitle: 'Kullanıcı Yönetimi'
        },
    },
    {
        path: '/user/add/',
        name: 'system-user-add',
        component: () => import('@/views/system/user/Detail.vue'),
        meta: {
            pageTitle: 'Kullanıcı Ekle',
            breadcrumb: [
                {
                    text: 'Kullanıcı Yönetimi',
                    to: {name: 'system-user-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/edit/:id',
        name: 'system-user-edit',
        component: () => import('@/views/system/user/Detail.vue'),
        meta: {
            pageTitle: 'Kullanıcı Değiştir',
            breadcrumb: [
                {
                    text: 'Kullanıcı Yönetimi',
                    to: {name: 'system-user-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/notification/list',
        name: 'system-notification-list',
        component: () => import('@/views/system/notification/List.vue'),
        meta: {
            pageTitle: 'Bildirim Yönetimi'
        },
    },
    {
        path: '/notification/add/',
        name: 'system-notification-add',
        component: () => import('@/views/system/notification/Detail.vue'),
        meta: {
            pageTitle: 'Bildirim Ekle',
            breadcrumb: [
                {
                    text: 'Bildirim Yönetimi',
                    to: {name: 'system-notification-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/page/list',
        name: 'system-page-list',
        component: () => import('@/views/system/page/List.vue'),
        meta: {
            pageTitle: 'Sayfa Yönetimi'
        },
    },
    {
        path: '/page/edit/:id',
        name: 'system-page-edit',
        component: () => import('@/views/system/page/Detail.vue'),
        meta: {
            pageTitle: 'Sayfa Değiştir',
            breadcrumb: [
                {
                    text: 'Sayfa Yönetimi',
                    to: {name: 'system-page-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/definition/list',
        name: 'system-definition-list',
        component: () => import('@/views/system/definition/List.vue'),
        meta: {
            pageTitle: 'Tanım Yönetimi'
        },
    },
    {
        path: '/definition/edit/:id',
        name: 'system-definition-edit',
        component: () => import('@/views/system/definition/Detail.vue'),
        meta: {
            pageTitle: 'Tanım Değiştir',
            breadcrumb: [
                {
                    text: 'Tanım Yönetimi',
                    to: {name: 'system-definition-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
]
