export default [
    {
        path: '/customer-complaint/list',
        name: 'customer-complaint-list',
        component: () => import('@/views/customer_complaint/List.vue'),
        meta: {
            pageTitle: 'Müşteri Şikayetleri'
        },
    },
    {
        path: '/customer-complaint/add/',
        name: 'customer-complaint-add',
        component: () => import('@/views/customer_complaint/Detail.vue'),
        meta: {
            pageTitle: 'Müşteri Şikayeti Ekle',
            breadcrumb: [
                {
                    text: 'Müşteri Şikayetleri',
                    to: {name: 'customer-complaint-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/customer-complaint/edit/:id',
        name: 'customer-complaint-edit',
        component: () => import('@/views/customer_complaint/Detail.vue'),
        meta: {
            pageTitle: 'Müşteri Şikayeti Değiştir',
            breadcrumb: [
                {
                    text: 'Müşteri Şikayetleri',
                    to: {name: 'customer-complaint-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
]
