export default [
    {
        path: '/expense/list',
        name: 'expense-list',
        component: () => import('@/views/expense/List.vue'),
        meta: {
            pageTitle: 'Masraf Formları'
        },
    },
    {
        path: '/expense/add/',
        name: 'expense-add',
        component: () => import('@/views/expense/Detail.vue'),
        meta: {
            pageTitle: 'Masraf Formu Ekle',
            breadcrumb: [
                {
                    text: 'Masraf Formları',
                    to: {name: 'expense-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/expense/edit/:id',
        name: 'expense-edit',
        component: () => import('@/views/expense/Detail.vue'),
        meta: {
            pageTitle: 'Masraf Formu Değiştir',
            breadcrumb: [
                {
                    text: 'Masraf Formları',
                    to: {name: 'expense-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
]
