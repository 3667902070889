export default [
    {
        path: '/activity/list',
        name: 'activity-list',
        component: () => import('@/views/activity/List.vue'),
        meta: {
            pageTitle: 'Aktivite İşlemleri'
        },
    },
    {
        path: '/activity/add/',
        name: 'activity-add',
        component: () => import('@/views/activity/Detail.vue'),
        meta: {
            pageTitle: 'Aktivite Ekle',
            breadcrumb: [
                {
                    text: 'Aktivite İşlemleri',
                    to: {name: 'activity-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/activity/edit/:id',
        name: 'activity-edit',
        component: () => import('@/views/activity/Detail.vue'),
        meta: {
            pageTitle: 'Aktivite Değiştir',
            breadcrumb: [
                {
                    text: 'Aktivite İşlemleri',
                    to: {name: 'activity-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
]
