export default [
    {
        path: '/form-request/list',
        name: 'form-request-list',
        component: () => import('@/views/form_request/List.vue'),
        meta: {
            pageTitle: 'Talep Formları'
        },
    },
    {
        path: '/form-request/add/',
        name: 'form-request-add',
        component: () => import('@/views/form_request/Detail.vue'),
        meta: {
            pageTitle: 'Talep Formu Ekle',
            breadcrumb: [
                {
                    text: 'Talep Formları',
                    to: {name: 'form-request-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form-request/edit/:id',
        name: 'form-request-edit',
        component: () => import('@/views/form_request/Detail.vue'),
        meta: {
            pageTitle: 'Talep Formu Değiştir',
            breadcrumb: [
                {
                    text: 'Talep Formları',
                    to: {name: 'form-request-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form-request/revision/:id',
        name: 'form-request-revision',
        component: () => import('@/views/form_request/Detail.vue'),
        meta: {
            pageTitle: 'Talep Formu Revizyon',
            breadcrumb: [
                {
                    text: 'Talep Formları',
                    to: {name: 'form-request-list'}
                },
                {
                    text: 'Revizyon',
                    active: true,
                },
            ],
        },
    },
]